import {
  container,
  mrAuto,
  mlAuto,
  //description,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";

const descriptionStyle = {
  container,
  textCenter: {
    textAlign: "center",
  },
  aboutDescription: {
    padding: "40px 0 0 0",
  },
  mrAuto,
  mlAuto,
  description: {
    marginBottom: "40px",
    color: grayColor[0],
  },
};

export default descriptionStyle;
