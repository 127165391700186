/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import NumberFormat from "react-number-format";

import { secondaryColor } from "assets/jss/material-kit-pro-react.js";

//redux
import { useDispatch } from "react-redux";
import { addToCart } from "state/cart";
import { useSelector } from "react-redux";
import { loadProduct } from "state/products";
import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
const useStyles = makeStyles(styles);
const useStyle = makeStyles(() => ({
  cartIconAcive: {
    color: secondaryColor,
  },
}));

const CardProduct = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const classeCart = useStyle();
  const {
    id,
    slug,
    sku,
    name,
    category_name,
    thumbnail,
    price,
    old_price,
    discount,
    baseURL,
  } = props;

  const addProduct = () => {
    setCart(true);
    let urlImage = baseURL + thumbnail;
    dispatch(
      addToCart({
        sku: sku,
        id: id,
        name: name["pt"],
        price: price,
        total_price: price,
        thumbnail: urlImage,
        quantity: 1,
      })
    );
  };

  const cart = useSelector((state) => state.cart.products);

  const [CartActive, setCart] = useState(false);
  let active = false;
  if (
    cart.filter(function (e) {
      return e.id === sku;
    }).length > 0
  ) {
    active = true;
  }

  return (
    <Card plain product>
      <CardHeader noShadow image>
        <Link
          onClick={() => dispatch(loadProduct({ id: slug }))}
          to={{ pathname: `p-page/${slug}` }}
        >
          <img src={`${baseURL}${thumbnail}`} />
        </Link>
      </CardHeader>
      <CardBody plain>
        <a href="#pablo">
          <h4 className={classes.cardTitle}>{name["pt"]}</h4>
        </a>
        <div>
          <p className={classes.description}>{sku}</p>
          {discount != 0 ? (
            <Grid container justify="flex-end" alignItems="flex-end">
              <Avatar
                style={{
                  fontWeight: "500",
                  fontSize: "15px",
                  marginLeft: "-15px",
                  marginTop: "-15px",
                  marginBottom: "-25px",
                  backgroundColor: "#F00026",
                }}
              >
                -{discount}%
              </Avatar>
            </Grid>
          ) : (
            ""
          )}
        </div>
        <p className={classes.description}>{category_name["pt"]}</p>
      </CardBody>
      <CardFooter plain className={classes.justifyContentBetween}>
        <div className={classes.priceContainer}>
          <Box m={1}>
            <NumberFormat
              value={price}
              className={classes.price}
              displayType={"text"}
              decimalSeparator=","
              suffix={"€"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Box>
          {old_price ? (
            <Box m={1}>
              <div>
                <span
                  style={{
                    marginRight: "6px",
                    fontSize: "10px",
                  }}
                >
                  PVPR
                </span>

                <NumberFormat
                  value={old_price}
                  className={classes.oldPrice}
                  displayType={"text"}
                  decimalSeparator=","
                  suffix={"€"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </div>{" "}
            </Box>
          ) : (
            ""
          )}
        </div>
        <Tooltip
          id="tooltip-top"
          title="Guardar no carinho"
          placement="left"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton className={classes.pullRight} onClick={addProduct}>
            <ShoppingCartIcon
              className={CartActive || active ? classeCart.cartIconAcive : ""}
            />
          </IconButton>
        </Tooltip>
      </CardFooter>
    </Card>
  );
};

CardProduct.propTypes = {
  name: PropTypes.shape({
    pt: PropTypes.string,
  }),
  category_name: PropTypes.shape({
    pt: PropTypes.string,
  }),
  sku: PropTypes.string,
  slug: PropTypes.string,
  baseURL: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.shape({
    pt: PropTypes.string,
  }),
  thumbnail: PropTypes.string,
  price: PropTypes.number,
  old_price: PropTypes.number,
  discount: PropTypes.number,
  id: PropTypes.number,
  addProductToCart: PropTypes.func,
};

export default CardProduct;
