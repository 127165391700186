/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "banners",
  initialState: {
    banners: [],

    loading: false,
    lastFetch: null,
  },
  reducers: {
    bannersRequested: (banners, action) => {
      banners.loading = true;
    },

    bannersReceived: (banners, action) => {
      console.log(banners);
      banners.banners = action.payload;
      banners.loading = false;
      banners.lastFetch = Date.now();
    },

    bannersRequestFailed: (banners, action) => {
      banners.loading = false;
    },
  },
});

export default slice.reducer;

const { bannersRequested, bannersReceived, bannersRequestFailed } =
  slice.actions;

const url = "/banners";

export const loadBanners = () => (dispatch, getState) => {
  const { lastFetchCategories } = getState().filters;

  const diffInMinutes = moment().diff(lastFetchCategories, "minute");
  if (diffInMinutes < 10) {
    return;
  }
  let response = dispatch(
    apiCallBegan({
      url: url,
      onStart: bannersRequested.type,
      onSuccess: bannersReceived.type,
      onError: bannersRequestFailed.type,
    })
  );

  return response;
};
