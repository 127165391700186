import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import NumberFormat from "react-number-format";
import CircularIndeterminate from "./CircularIndeterminate";

import Header from "./HeaderCustom.js";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import CardFooter from "components/Card/CardFooter.js";

import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
// core components

import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import ButtonMUI from "@material-ui/core/Button";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
// eslint-disable-next-line no-unused-vars
import { useDispatch } from "react-redux";

const useStyles = makeStyles(shoppingCartStyle);
import { removeFromCart } from "state/cart";
import { subtractQuantity } from "state/cart";
import { addQuantity } from "state/cart";
import OrderForm from "./OrderForm.js";
import Typography from "@material-ui/core/Typography";

/* const handleSubmit = () => {
  console.log(handleSubmit);
}; */

const CartPage = () => {
  const history = useHistory();

  const classes = useStyles();
  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  var itens = [];
  itens = cart.products.map((itemMakerObj, index) => {
    return [
      <span key={1}>
        <div className={classes.imgContainer} key={index}>
          <img src={itemMakerObj.thumbnail} alt="..." className={classes.img} />
        </div>
      </span>,
      <span key={2}>
        {/*  <a href="" className={classes.tdNameAnchor}> */}
        {itemMakerObj.name}
        {/*   </a> */}
        <br />
        <small className={classes.tdNameSmall}>{itemMakerObj.sku}</small>
      </span>,
      <span key={3}>
        {" "}
        <NumberFormat
          // className={classes.oldPrice}
          displayType={"text"}
          decimalSeparator=","
          suffix={"€"}
          decimalScale={2}
          fixedDecimalScale={true}
          value={itemMakerObj.price}
        />
      </span>,
      <span key={4}>
        <Grid container alignItems="center">
          <Grid item md={4}>
            <Button
              onClick={() => {
                dispatch(
                  subtractQuantity({
                    id: itemMakerObj.id,
                    price: itemMakerObj.price,
                  })
                );
              }}
              color="info"
              size="sm"
              // round
              className={classes.firstButton}
            >
              <Remove />
            </Button>
          </Grid>
          <Grid item md={4}>
            <Box m={2}>{itemMakerObj.quantity}</Box>
          </Grid>
          <Grid item md={4}>
            <Button
              color="info"
              size="sm"
              // round
              className={classes.lastButton}
              onClick={() => {
                dispatch(
                  addQuantity({
                    id: itemMakerObj.id,
                    price: itemMakerObj.price,
                  })
                );
              }}
            >
              <Add />
            </Button>
          </Grid>
        </Grid>
      </span>,
      //  <span key={3}>{itemMakerObj.price} €</span>,
      <span key={5}>
        <NumberFormat
          // className={classes.oldPrice}
          displayType={"text"}
          decimalSeparator=","
          suffix={"€"}
          decimalScale={2}
          fixedDecimalScale={true}
          value={itemMakerObj.price * itemMakerObj.quantity}
        />
      </span>,
      <span key={6}>
        <Button
          link
          onClick={() => {
            dispatch(
              removeFromCart({ id: itemMakerObj.id, price: itemMakerObj.price })
            );
          }}
        >
          <Close />
        </Button>
      </span>,
    ];
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header />
      <Parallax
        image={require("assets/img/examples/bg2.jpg").default}
        filter="dark"
        small
      ></Parallax>
      {cart.status == null ? (
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Card plain>
              <CardBody plain>
                {/* <h3 className={classes.cardTitle}>Carinho</h3> */}
                <Table
                  tableHead={["", "PRODUTO", "PREÇO", "QTY", "SUBTOTAL", ""]}
                  tableData={itens}
                  tableShopping
                  customHeadCellClasses={[
                    classes.textCenter,
                    classes.description,
                    classes.description,
                    classes.textLeft,
                    classes.description,
                    classes.textLeft,
                  ]}
                  customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
                  customCellClasses={[
                    classes.imgContainer,
                    classes.tdName,
                    classes.tdPrice,
                    classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
                    classes.tdPrice,
                    classes.tdNumberSmall,
                  ]}
                  customClassesForCells={[0, 1, 2, 3, 4, 5]}
                />
              </CardBody>
              <CardFooter>
                <Grid container spacing={4} direction="row-reverse">
                  <Grid item>
                    <Box sx={{ marginRight: "20px" }}>
                      {" "}
                      <NumberFormat
                        className={classes.total}
                        displayType={"text"}
                        decimalSeparator=","
                        suffix={"€"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={cart.total}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" align="center">
                      Total
                    </Typography>
                  </Grid>
                </Grid>
              </CardFooter>
            </Card>
          </div>
          <div style={{ width: "100%" }}>
            {cart.loading ? (
              <Box m={10}>
                <CircularIndeterminate />;
              </Box>
            ) : (
              <OrderForm></OrderForm>
            )}
          </div>
        </div>
      ) : (
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Card plain>
              <CardBody plain>
                <Grid container spacing={4} direction="column">
                  <Grid item>
                    <Box>
                      <Typography variant="h3" align="center">
                        Obrigado!
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" align="center">
                      Encomenda submetida com sucesso.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" align="center">
                      Código da encomenda
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" align="center">
                      {cart.refOrder}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" align="center">
                      Será enviado um e-mail com os dados da encomenda e meios
                      de pagamento.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="row"
                    >
                      <Grid item>
                        <Box m={3}>
                          <ButtonMUI
                            variant="contained"
                            color="secondary"
                            type="submit"
                            onClick={() => history.push("/ecpage")}
                          >
                            Voltar à loja!
                          </ButtonMUI>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </div>
        </div>
      )}

      <Box m={15}></Box>
    </div>
  );
};

export default CartPage;
