import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const initialState = {
  total: 0,
  quantity: 0,
  products: [],
  loading: false,
  status: null,
  refOrder: null,
};

const slice = createSlice({
  name: "cartProduts",
  initialState: initialState,
  reducers: {
    addToCart: (cart, action) => {
      if (cart.status != null) {
        cart.status = null;
        cart.refOrder = null;
      }
      const index = cart.products.findIndex(
        (product) => product.id === action.payload.id
      );
      if (index === -1) cart.products.push(action.payload);
      else cart.products[index].quantity = cart.products[index].quantity + 1;

      cart.quantity = cart.quantity + 1;
      cart.total += action.payload.price;
    },
    removeFromCart: (cart, action) => {
      let qtd = 0;
      cart.products = cart.products.filter(function (item) {
        if (item.id !== action.payload.id) {
          return item;
        } else qtd = item.quantity;
      });

      cart.quantity -= qtd;
      cart.total -= action.payload.price * qtd;
    },
    addQuantity: (cart, action) => {
      const index = cart.products.findIndex(
        (product) => product.id === action.payload.id
      );
      if (index >= 0) cart.products[index].quantity += 1;

      cart.quantity += 1;
      cart.total += action.payload.price;
    },
    subtractQuantity: (cart, action) => {
      const index = cart.products.findIndex(
        (product) => product.id === action.payload.id
      );
      let product = cart.products[index];
      if (product)
        if (product.quantity !== 1) {
          product.quantity -= 1;
          cart.quantity -= 1;
          cart.total -= action.payload.price;
        } else {
          product.quantity = 1;
        }
    },
    emptyCart: (cart) => {
      cart.products = [];
    },
    OrderRequested: (cart) => {
      cart.loading = true;
    },
    OrderStatusReceived: (cart, action) => {
      console.log(action);
      cart.status = action.payload.status;
      cart.refOrder = action.payload.order.code;
      cart.total = 0;
      cart.quantity = 0;
      cart.products = [];
      cart.loading = false;
    },
    OrderFailed: (cart) => {
      cart.loading = false;
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  addQuantity,
  subtractQuantity,
  emptyCart,
  OrderRequested,
  OrderStatusReceived,
  OrderFailed,
} = slice.actions;
export default slice.reducer;

const urlSubmitOrder = "orders";

export const submitOrder =
  ({ data: data }) =>
  (dispatch, getState) => {
    const { products } = getState().cart;
    data.products = products;
    console.log("submitOrder");
    dispatch(
      apiCallBegan({
        url: urlSubmitOrder,
        method: "POST",
        data,
        onStart: OrderRequested.type,
        onSuccess: OrderStatusReceived.type,
        onError: OrderFailed.type,
      })
    );
  };
