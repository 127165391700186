import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    position: "relative",
    left: "50%",
    //marginBottom: "5px",
    // top: "50%",
    // transform: "translate(-50%, -50%)",
  },
}));

export default function CircularIndeterminate(props) {
  const classes = useStyles();
  console.log(props);
  return (
    <div
      className={classes.root}
      style={{
        left: props.left ? props.left + "%" : classes.root.left,
      }}
    >
      <CircularProgress thickness={3.6} size={60} color={"secondary"} />
    </div>
  );
}

CircularIndeterminate.propTypes = {
  left: PropTypes.string,
};
