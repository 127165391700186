import { React } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Header from "./HeaderCustom.js";
/* import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem"; */
import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Slider from "./Banner/Slider";

import SectionProductsCustom from "views/EcommercePage/Sections/SectionProductsCustom.js";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";

const useStylesEcommerce = makeStyles(styles);

const ECPage = () => {
  const classesEC = useStylesEcommerce();

  return (
    <div>
      <Header />

      <Slider />

      <div className={classNames(classesEC.main, classesEC.mainRaised)}>
        <SectionProductsCustom />
      </div>
      <Footer
        theme="dark"
        content={
          <div>
            <div className={classesEC.left}>
              {/*              <List className={classesEC.list}>
                <ListItem className={classesEC.inlineBlock}>
                  <a
                    href="http://blog.creative-tim.com/?ref=mkpr-e-ecommerce"
                    target="_blank"
                    className={classesEC.block}
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classesEC.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/presentation?ref=mkpr-e-ecommerce"
                    target="_blank"
                    className={classesEC.block}
                    rel="noreferrer"
                  >
                    Presentation
                  </a>
                </ListItem>
                <ListItem className={classesEC.inlineBlock}>
                  <a
                    href="#pablito"
                    onClick={(e) => e.preventDefault()}
                    className={classesEC.block}
                  >
                    Discover
                  </a>
                </ListItem>
                <ListItem className={classesEC.inlineBlock}>
                  <a
                    href="#pablito"
                    onClick={(e) => e.preventDefault()}
                    className={classesEC.block}
                  >
                    Payment
                  </a>
                </ListItem>
                <ListItem className={classesEC.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/contact-us?ref=mkpr-e-ecommerce"
                    target="_blank"
                    className={classesEC.block}
                    rel="noreferrer"
                  >
                    Contact us
                  </a>
                </ListItem>
              </List>  */}
            </div>
            <div className={classesEC.right}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a
                href="https://technotion.pt"
                target="_blank"
                className={classesEC.aClasses}
                rel="noreferrer"
              >
                TECHNOTION
              </a>{" "}
              All Rights Reserved.
            </div>
          </div>
        }
      >
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Sobre nós</h5>
            <p>
              A InforMag têm mais de 15 anos de experiencia na área de serviços
              informáticos. Trabalhamos todos os dias para a satisfação dos nos
              clientes.
            </p>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Contactos</h5>
            <div className={classesEC.socialFeed}>
              <div>
                <i className="fas fa-map-pin" />
                <p>Rua da Barranha, 455, Senhora da Hora</p>
              </div>
              <div>
                <i className="fas fa-phone" />
                <p>911 065 420</p>
              </div>
              <div>
                <i className="fas fa-at" />
                <p>geral@informag.pt</p>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            {/* <h5>Instagram Feed</h5> */}
          </GridItem>
        </GridContainer>
      </Footer>
    </div>
  );
};

export default ECPage;
