import * as React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import sectionSubscribeLineStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionSubscribeLineStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Search from "@material-ui/icons/Search";
const useStyles = makeStyles(sectionSubscribeLineStyle);
import Box from "@material-ui/core/Box";

import { useDispatch } from "react-redux";
import { searchProducts, revertSearchProducts } from "state/products";
var flag = 0;

export default function SearchInput() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();

    const { value } = e.currentTarget;

    if (value.length > 2) {
      flag = 1;
      dispatch(searchProducts({ value: value }));
    }
    if (value.length == 0 && flag === 1) {
      dispatch(revertSearchProducts());
    }
  };

  const theme = createMuiTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        hd: 1280,
        uxga: 1600,
        desktop: 1601,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: {
            mobile: "100%",
            laptop: "90%",
          },
          marginTop: {
            mobile: 0,
            laptop: 5,
          },
          marginBottom: {
            mobile: 3,
            laptop: 5,
          },
          marginLeft: {
            laptop: 3,
          },
          marginRight: {
            laptop: 3,
          },
        }}
      >
        <CustomInput
          id="search"
          formControlProps={{
            fullWidth: true,
            className: classes.formControl,
          }}
          inputProps={{
            onChange: (event) => handleChange(event),
            startAdornment: (
              <InputAdornment position="start">
                <Search className={classes.icon} />
              </InputAdornment>
            ),
            placeholder: "Pesquise aqui!",
          }}
        />
      </Box>
    </ThemeProvider>
  );
}
