import cartReducer from "./cart";
import productsReducer from "./products";
import filtersReducer from "./filters";
import bannersReducer from "./banners";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  cart: cartReducer,
  products: productsReducer,
  filters: filtersReducer,
  banners: bannersReducer,
});

export default allReducers;
