/* eslint-disable no-unused-vars */
import { useEffect, React } from "react";
import CardProduct from "./CardProduct";
import { Grid } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import PropTypes from "prop-types";
import CircularIndeterminate from "./CircularIndeterminate";
import { useDispatch, useSelector } from "react-redux";
import { loadProducts } from "state/products";

const getItensCard = (itemMakerObj, baseURL, index) => {
  return (
    <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
      <CardProduct {...itemMakerObj} baseURL={baseURL} />
    </Grid>
  );
};

const Products = (props) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const baseURL = products.baseURL;

  useEffect(() => {
    console.log("dispatch(loadProducts());");
    dispatch(loadProducts());
  }, [dispatch]);

  return (
    <GridContainer container spacing={4}>
      {
        /* products.list && products.loading === false
        ? */ products.list
          .slice(0, products.displayPage * 10)
          .map(function (itemMakerObj, index) {
            // if (itemMakerObj.id > props.low && itemMakerObj.id < props.high)

            return getItensCard(
              itemMakerObj,
              baseURL,
              index,
              props.ifCheckedCat
            );
          })
        /* : "Loading..."*/
      }
    </GridContainer>
  );
};

Products.propTypes = {
  ifCheckedCat: PropTypes.bool,
  high: PropTypes.number,
  page: PropTypes.number,
};

export default Products;
