import { useState, React } from "react";

import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Slider from "@material-ui/core/Slider";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { updateRangePrice } from "state/filters";
import { setResetStatus } from "state/filters";
import { searchProducts } from "state/products";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";

const useStyles = makeStyles(styles);
var flag = 0;
export default function RangeSlider() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const filters = useSelector((state) => state.filters);

  const [value, setValue] = useState([
    products.lowerPrice,
    products.higherPrice,
  ]);

  if (filters.reset) {
    setValue([products.lowerPrice, products.higherPrice]);
    dispatch(
      setResetStatus({
        status: false,
      })
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    flag = 1;
    dispatch(
      updateRangePrice({ lowerPrice: newValue[0], biggestPrice: newValue[1] })
    );
    dispatch(searchProducts(""));
    dispatch(
      setResetStatus({
        status: false,
      })
    );
  };

  const classes = useStyles();

  return (
    <Box sx={{ p: 1 }}>
      <CardBody className={classes.cardBodyRefine}>
        <span className={classNames(classes.pullLeft, classes.priceSlider)}>
          €{!flag ? products.lowerPrice : value[0]}
        </span>
        <span className={classNames(classes.pullRight, classes.priceSlider)}>
          €{!flag ? products.higherPrice : value[1]}
        </span>
      </CardBody>
      <Slider
        min={products.lowerPrice}
        max={products.higherPrice}
        value={value}
        step={10}
        onChange={handleChange}
        valueLabelDisplay="auto"
        color="secondary"
        //aria-label="Default"
      />
    </Box>
  );
}
